import { FC, lazy, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Roles } from '../../models/Role';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import { TabStrip } from '../../components/shared/tab-strip/TabStrip';
import usePermissions from '../../hooks/permissions/usePermissions';
import { useFeatureFlags } from '../../contexts/FeatureFlagContext';

const OrgDetails = lazy(() => import('../../components/my-org/OrgDetails'));
const OrgFormDefaults = lazy(() => import('../../components/my-org/OrgFormDefaults'));
const OrgPickLists = lazy(() => import('../../components/my-org/OrgPickLists'));
const OrgPublicForms = lazy(() => import('../../components/my-org/OrgPublicForms'));
const PicklistBuilder = lazy(() => import('../admin/picklist-builder/PicklistBuilder'));
const UserPermissions = lazy(() => import('../../components/user/UserPermissions'));
const ModuleTemplateList = lazy(() => import('../admin/ModuleTemplateList'));
const FormTemplates = lazy(() => import('../admin/FormTemplates'));
const OrgDataExport = lazy(() => import('../../components/my-org/OrgDataExport'));
const OrgContacts = lazy(() => import('../../components/my-org/OrgContacts'));

const MyOrg: FC = () => {
  const { t } = useTranslation('organisation');
  const client = useRecoilValue(currentClientAtom);
  const clientId = useMemo(() => client?.id, [client?.id]);
  const hasPermission = usePermissions();

  const hasTeamLead = useMemo(() => hasPermission(Roles.TeamLead), [hasPermission]);
  const hasTeamMember = useMemo(() => hasPermission(Roles.TeamMember), [hasPermission]);
  const { featureFlags } = useFeatureFlags();

  return (
    <div className="h-full">
      <TabStrip
        allowWrapping
        borderless
        headerClassName="-mb-3 -mx-2"
        contentClassName="px-6 bg-background-1"
        enableHash
        enableSticky
        wrapper={TopNavPortal}
      >
        {hasTeamLead && <TabStrip.TabHeader id="details" text={t('tabs.details')} value={null} data-cy="org-tab" />}
        {hasTeamLead && <TabStrip.TabHeader id="user-permissions" text={t('tabs.user-permissions')} value={null} data-cy="users-tab" />}
        {hasTeamLead && <TabStrip.TabHeader id="forms" text={t('tabs.forms')} value={null} data-cy="forms-tab" />}
        {hasTeamLead && <TabStrip.TabHeader id="form-defaults" text={t('tabs.form-defaults')} value={null} data-cy="form-defaults-tab" />}
        {hasTeamLead && <TabStrip.TabHeader id="public-forms" text={t('tabs.public-forms')} value={null} data-cy="public-forms-tab" />}
        {hasTeamLead && <TabStrip.TabHeader id="modules" text={t('tabs.modules')} value={null} data-cy="modules-tab" />}
        {hasTeamLead && <TabStrip.TabHeader id="client-picklists" text={t('tabs.client-picklists')} value={null} data-cy="client-picklist-tab" />}
        {hasTeamLead && <TabStrip.TabHeader id="consultant-picklists" text={t('tabs.consultant-picklists')} value={null} data-cy="picklist-tab" />}
        {(hasTeamLead || hasTeamMember) && featureFlags.dataExport && (
          <TabStrip.TabHeader id="data-exports" text={t('tabs.data-exports')} value={null} data-cy="data-exports-tab" />
        )}
        {hasTeamLead && featureFlags.clientContacts && (
          <TabStrip.TabHeader id="contacts" text={t('tabs.contacts')} value={null} data-cy="contacts-tab" />
        )}

        {hasTeamLead && (
          <TabStrip.TabContent forId="details" data-cy="org-tab-content">
            <OrgDetails />
          </TabStrip.TabContent>
        )}
        {hasTeamLead && (
          <TabStrip.TabContent forId="user-permissions" data-cy="users-tab-content">
            <UserPermissions />
          </TabStrip.TabContent>
        )}
        {hasTeamLead && (
          <TabStrip.TabContent forId="forms" data-cy="forms-tab-content">
            <FormTemplates />
          </TabStrip.TabContent>
        )}
        {hasTeamLead && (
          <TabStrip.TabContent forId="form-defaults" data-cy="form-defaults-tab-content">
            <OrgFormDefaults />
          </TabStrip.TabContent>
        )}
        {hasTeamLead && (
          <TabStrip.TabContent forId="public-forms" data-cy="public-forms-content">
            <OrgPublicForms />
          </TabStrip.TabContent>
        )}
        {hasTeamLead && (
          <TabStrip.TabContent forId="modules" data-cy="modules-content">
            <ModuleTemplateList />
          </TabStrip.TabContent>
        )}
        {hasTeamLead && (
          <TabStrip.TabContent forId="client-picklists" data-cy="client-picklist-content">
            <PicklistBuilder clientId={clientId} />
          </TabStrip.TabContent>
        )}
        {hasTeamLead && (
          <TabStrip.TabContent forId="consultant-picklists" data-cy="picklist-content">
            <OrgPickLists />
          </TabStrip.TabContent>
        )}
        {(hasTeamLead || hasTeamMember) && featureFlags.dataExport && (
          <TabStrip.TabContent forId="data-exports" data-cy="data-exports">
            <OrgDataExport />
          </TabStrip.TabContent>
        )}
        {hasTeamLead && featureFlags.clientContacts && (
          <TabStrip.TabContent forId="contacts" data-cy="contacts-content">
            <OrgContacts />
          </TabStrip.TabContent>
        )}
      </TabStrip>
    </div>
  );
};

export default MyOrg;
